"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RArcExporter = exports.RShapesExporter = exports.RPainterPathExporter = exports.Exporter = void 0;
const ltentity_1 = require("./ltentity");
const ltmath_1 = require("./ltmath");
const ltpoint_1 = require("./ltpoint");
const segment_1 = require("./segment");
const PointTolerance = 1e-9;
const AngleTolerance = 1e-9;
const RNANDOUBLE = 10000000000;
const M_PI_2 = 1.57079632679489661923132169163975144;
class Exporter {
    constructor() {
        this._line_type = null;
        this.pixelSizeHint = 0.5;
        this.draftMode = false;
    }
    exportEllipse(ellipse, offset = 0) {
        if (ellipse.getMajorRadius() < PointTolerance ||
            ellipse.getMinorRadius() < PointTolerance) {
            return;
        }
        let polyline = new segment_1.RPolyline([]);
        let cp = ellipse.getCenter();
        let radius1 = ellipse.getMajorRadius();
        let radius2 = ellipse.getMinorRadius();
        let angle = ellipse.getAngle();
        let a1 = ellipse.getStartParam();
        let a2 = ellipse.getEndParam();
        let reversed = ellipse.isReversed();
        let aStep = 5; // Angle Step (rad)
        let a; // Current Angle (rad)
        aStep = Math.PI / 36;
        let vp = new ltpoint_1.LTPoint(0, 0);
        let vc = new ltpoint_1.LTPoint(cp.x, cp.y);
        vp.x = cp.x + Math.cos(a1) * radius1;
        vp.y = cp.y + Math.sin(a1) * radius2;
        vp.rotate(angle, vc);
        polyline.appendVertex(vp.clone());
        if (!reversed) {
            // Arc Counterclockwise:
            if (a1 > a2 - AngleTolerance) {
                a2 += 2 * Math.PI;
            }
            for (a = a1 + aStep; a <= a2; a += aStep) {
                vp.x = cp.x + Math.cos(a) * radius1;
                vp.y = cp.y + Math.sin(a) * radius2;
                vp.rotate(angle, vc);
                polyline.appendVertex(new ltpoint_1.LTPoint(vp.x, vp.y));
            }
        }
        else {
            // Arc Clockwise:
            if (a1 < a2 + AngleTolerance) {
                a2 -= 2 * Math.PI;
            }
            for (a = a1 - aStep; a >= a2; a -= aStep) {
                vp.x = cp.x + Math.cos(a) * radius1;
                vp.y = cp.y + Math.sin(a) * radius2;
                vp.rotate(angle, vc);
                polyline.appendVertex(new ltpoint_1.LTPoint(vp.x, vp.y));
            }
        }
        vp.x = cp.x + Math.cos(a2) * radius1;
        vp.y = cp.y + Math.sin(a2) * radius2;
        vp.rotate(angle, vc);
        let last_vertex = polyline.vertices[polyline.countVertices() - 1];
        if (!last_vertex.equalsFuzzy(vp)) {
            polyline.appendVertex(new ltpoint_1.LTPoint(vp.x, vp.y));
        }
        this.exportPolyline(polyline, true, offset);
    }
    setLinetypePattern(line_type) {
        this._line_type = line_type;
    }
    getLinetypePattern() {
        return this._line_type;
    }
    isPatternContinuous(p) {
        if (p === null) {
            return true;
        }
        return p.getNumDashes() <= 1;
    }
    exportPolyline(polyline, polylineGen, offset) {
        //好像没用到这个接口
        let continuous = true; //this.getEntity() == null || this.isPatternContinuous(p);
        if (!continuous) {
            // p.scale(getLineTypePatternScale(p));
            if (isNaN(offset)) {
                let length = polyline.getLength();
                //let p = this.getLinetypePattern();
                //offset = p?.getPatternOffset(length);
            }
        }
        // if polyline has individual segment widths, polylineGen has no effect:
        if (!continuous && polylineGen && !polyline.hasWidths()) {
            // pattern along whole polyline:
            // exportExplodable(polyline, offset);
        }
        else {
            if (polyline.hasWidths()) {
                this.exportThickPolyline(polyline);
            }
            else {
                // pattern for each individual segment:
                for (let i = 0; i < polyline.countSegments(); i++) {
                    let shape = polyline.getSegmentAt(i);
                    if (shape instanceof segment_1.RLine) {
                        this.exportLine(shape);
                    }
                    else if (shape instanceof segment_1.RArc) {
                        this.exportArc(shape);
                    }
                }
            }
        }
    }
    exportShapeSegment(shape, angle) {
        if (shape == undefined || shape == null) {
            return;
        }
        if (shape instanceof segment_1.RLine) {
            this.exportLineSegment(shape, angle);
        }
        else if (shape instanceof segment_1.RArc) {
            this.exportArcSegment(shape);
        }
    }
    exportPoint(shape, angle) {
        if (shape == undefined || shape == null) {
            return;
        }
    }
    exportLine(line, offset = 0) {
        let length = line.getLength();
        if (length > 1e100 || !ltmath_1.LTMath.isSane(length)) {
            return false;
        }
        if (length < 1.0e-6) {
            this.exportLineSegment(line, 0.0);
            return true;
        }
        let angle = line.getAngle();
        //
        //let line = new LTLine(new LTPoint(0, 0), new LTPoint(length, 0));
        let dv = line.getEndPoint().minus(line.getStartPoint()).getNormalized();
        if (length == 0 || dv == null || (dv.getx() == 0 && dv.gety() == 0)) {
            return false;
        }
        let vp = [];
        let pattern_map = [];
        let dash_length = 0;
        let line_pattern = this.getLinetypePattern();
        if (line_pattern == null || !line_pattern.isValid() || line_pattern.getNumDashes() <= 1) {
            this.exportLineSegment(line, angle);
            return false;
        }
        let dash_scale = 1.0;
        let pattern = line_pattern._pattern;
        for (let i = 0; i < pattern.length; ++i) {
            let g = pattern[i] * dash_scale;
            let l = Math.abs(g);
            dash_length = dash_length + l;
            vp.push(dv.dot(l));
            pattern_map.push(g);
        }
        //优化之后的;
        if ((length / dash_length) > 1000) {
            return false;
        }
        if (isNaN(offset)) {
            offset = line_pattern.getPatternOffset(length);
        }
        else {
            let num = Math.ceil(offset / dash_length);
            offset = offset - num * dash_length;
        }
        let i = 0;
        let cursor = line.getStartPoint().plus(dv.dot(offset));
        let total = offset;
        let nextTotal;
        let isGap = false;
        let done = false;
        let dash;
        let ret = 0;
        let count = 0;
        let index = 0;
        let pointtolerance = PointTolerance;
        do {
            let dashLength = pattern_map[i];
            nextTotal = total + Math.abs(dashLength);
            //判断是否是缺口
            if (dashLength > -pointtolerance) {
                isGap = false;
            }
            else {
                isGap = true;
            }
            if (nextTotal > -pointtolerance) {
                //获取下个绘制的线段
                dash = new segment_1.RLine(cursor, new ltpoint_1.LTPoint(cursor.getx() + vp[i].getx(), cursor.gety() + vp[i].gety()));
                if (!isGap) {
                    ret = -nextTotal;
                }
                else {
                    ret = nextTotal;
                }
                if (total < 0.0) {
                    dash.setStartPoint(line.getStartPoint());
                    ret = NaN;
                }
                if (nextTotal >= length - 1.0e-6) {
                    dash.setEndPoint(line.getEndPoint());
                    ret = NaN;
                }
                if (!isGap) {
                    //进行绘制
                    this.exportLineSegment(dash, angle);
                    ret = nextTotal;
                }
            }
            //计算当前的点
            cursor = new ltpoint_1.LTPoint(cursor.getx() + vp[i].getx(), cursor.gety() + vp[i].gety());
            total = nextTotal;
            //是否结束
            done = total > length || isNaN(total);
            //如有有图形的情况则绘制图形
            let ent = line_pattern.getShapes(i);
            if (ent != null) {
                let entclone = ent.clone();
                this.exportLinetypeShape(entclone, line, total, length, angle, cursor);
            }
            else {
                //break;
            }
            ++i;
            //周期结束从头开始
            if (i >= line_pattern.getNumDashes()) {
                i = 0;
            }
        } while (!done);
        return true;
    }
    exportArc(arc) {
        this.exportArcSegment(arc);
    }
    exportThickPolyline(line) {
    }
    exportLinetypeShape(pps, line, total, length, angle, cursor) {
        let min = pps.getBoundingBox().getMinimum();
        let max = pps.getBoundingBox().getMaximum();
        let isCursorOnLine = line.isOnShape(cursor);
        let diffBefore = total + min.x;
        let diffAfter = total + max.x - length;
        let shapeOutsideBefore = diffBefore < -PointTolerance;
        let shapeOutsideAfter = diffAfter > PointTolerance;
        if (isCursorOnLine && (!shapeOutsideBefore && !shapeOutsideAfter)) {
            this.exportPainterPaths(pps, angle, cursor);
            return true;
        }
        else {
            if (shapeOutsideBefore) {
                // check if first shape is not entirely before the start point of the line:
                if (total + max.x < 0.0) {
                    return false;
                }
                let l = line.clone();
                if (Math.abs(total + max.x) < length) {
                    let p = new ltpoint_1.LTPoint(Math.cos(angle) * Math.abs(total + max.x), Math.sin(angle) * Math.abs(total + max.x));
                    l.setEndPoint(l.getStartPoint().plus(p));
                }
                this.exportLineSegment(l, angle);
                return true;
            }
            if (shapeOutsideAfter) {
                // check if last shape is not entirely after the end point of the line:
                if (total + min.x > length) {
                    return false;
                }
                let l = line.clone();
                if (Math.abs(total + min.x) > 0.0) {
                    let p = new ltpoint_1.LTPoint(Math.cos(angle) * Math.abs(total + min.x), Math.sin(angle) * Math.abs(total + min.x));
                    l.setStartPoint(l.getStartPoint().plus(p));
                }
                this.exportLineSegment(l, angle);
                return true;
            }
            return false;
        }
    }
    getCurrentPixelSizeHint() {
        return 0.5;
    }
    getMinArcAngleStep() {
        return 10 * Math.PI / 180.0;
    }
    setDraftMode(on) {
        this.draftMode = on;
        if (this.draftMode) {
            //  screenBasedLinetypes = false;
        }
    }
    exportArcSegment(arc, allowForZeroLength = false) {
        if (allowForZeroLength && arc.isFullCircle()) {
            this.exportLineSegment(new segment_1.RLine(arc.getStartPoint(), arc.getEndPoint()), arc.getDirection1());
            return;
        }
        let segmentLength;
        if (this.pixelSizeHint > 0.0) {
            // approximate arc with segments with the length of 2 pixels:
            segmentLength = this.getCurrentPixelSizeHint() * 2;
        }
        else {
            segmentLength = arc.getRadius() / 40.0;
        }
        // avoid a segment length of 0:
        if (segmentLength < 1.0e-4) {
            segmentLength = 1.0e-4;
        }
        let a1 = arc.getStartAngle();
        let a2 = arc.getEndAngle();
        let center = arc.getCenter();
        let radius = arc.getRadius();
        let aStep;
        if (radius < 1.0e-3) {
            aStep = 0.1;
        }
        else {
            aStep = segmentLength / radius;
            if (aStep > 1.0) {
                aStep = 1.0;
            }
            let minAStep = this.getMinArcAngleStep();
            if (this.draftMode) {
                minAStep *= 4;
            }
            if (aStep < minAStep) {
                aStep = minAStep;
            }
        }
        if (aStep < 1) {
        }
        let prev = arc.getStartPoint();
        let ci = new ltpoint_1.LTPoint(0, 0);
        let a;
        //    RPolyline pl;
        if (!arc.isReversed) {
            // Arc Counterclockwise:
            if (a1 > a2 - AngleTolerance) {
                a2 += 2 * Math.PI;
            }
            for (a = a1 + aStep; a <= a2; a += aStep) {
                ci.x = center.x + Math.cos(a) * radius;
                ci.y = center.y + Math.sin(a) * radius;
                this.exportLineSegment(new segment_1.RLine(new ltpoint_1.LTPoint(prev.x, prev.y), new ltpoint_1.LTPoint(ci.x, ci.y)), a + M_PI_2);
                //            pl.appendVertex(ci);
                prev = new ltpoint_1.LTPoint(ci.x, ci.y);
            }
        }
        else {
            // Arc Clockwise:
            if (a1 < a2 + AngleTolerance) {
                a2 -= 2 * Math.PI;
            }
            for (a = a1 - aStep; a >= a2; a -= aStep) {
                ci.x = center.x + Math.cos(a) * radius;
                ci.y = center.y + Math.sin(a) * radius;
                this.exportLineSegment(new segment_1.RLine(prev, ci.clone()), a + M_PI_2);
                //            pl.appendVertex(ci);
                prev = new ltpoint_1.LTPoint(ci.x, ci.y);
            }
        }
        this.exportLineSegment(new segment_1.RLine(prev, arc.getEndPoint()), arc.getEndAngle() + M_PI_2);
    }
    exportCircle(circle) {
        let arc = new segment_1.RArc(circle.getCenter(), circle.getRadius(), 0.0, 2 * Math.PI, false);
        this.exportArc(arc);
    }
}
exports.Exporter = Exporter;
class RPainterPathExporter extends Exporter {
    constructor() {
        super();
        this._canvas = null;
        this._entity = null;
        this._color = null;
    }
    exportEntity(canvas, entity, line_type, offset, graphics) {
        this._entity = entity;
        this._color = this._entity.entityColor(canvas.map);
        this._line_type = line_type;
        this._canvas = canvas;
        if (line_type !== null && line_type !== undefined && line_type.isValid()) {
            if (this._entity instanceof ltentity_1.LTCircle) {
                this.exportCircle(this._entity.subData());
            }
            else if (this._entity instanceof ltentity_1.LTPolyline) {
                this.exportPolyline(this._entity.subData(), false, offset);
            }
            else if (this._entity instanceof ltentity_1.LTLine) {
                this.exportLine(this._entity.subData(), offset);
            }
        }
        else {
            if (this._entity instanceof ltentity_1.LTEllipse) {
                this.exportEllipse(this._entity.subData());
            }
        }
        return true;
    }
    setCanvas(canvas) {
        this._canvas = canvas;
    }
    getCanvas() {
        return this._canvas;
    }
    exportLineSegment(line, angle) {
        if (this._canvas == null) {
            return;
        }
        if (line.getLength() < PointTolerance) {
            //绘制点
            this.exportPoint(line.getStartPoint(), angle);
        }
        this._canvas.drawLine(line.getStartPoint(), line.getEndPoint());
        return;
    }
    exportPoint(point, angle) {
        if (this._canvas !== null) {
            this._canvas.drawPoint(point.x, point.y);
        }
    }
    exportPainterPaths(ent, angle, cursor) {
        if (this._entity == null || this._canvas == null) {
            return;
        }
        if (ent instanceof ltentity_1.LTText) {
            ent.subData().setRotate(angle);
            ent.json_value.mtext = false;
            ent.move(cursor);
            ent.setCenter(true);
            ent.setColor(this._color);
            ent.worldDraw(this._canvas, false, { ent: this._entity }, null);
        }
        else if (ent instanceof ltentity_1.LTPolyline) {
            ent.subData().rotate(angle, new ltpoint_1.LTPoint(0, 0));
            ent.move(cursor);
            ent.setStrokeWidth(0);
            ent.worldDraw(this._canvas, false, null, null);
        }
    }
}
exports.RPainterPathExporter = RPainterPathExporter;
class RShapesExporter extends Exporter {
    constructor(exporter, entitys, line_type, isdraw, offset, graphics) {
        super();
        this.lengthAt = [];
        this.shapes = [];
        this._result_ok = true;
        this._exporter = exporter;
        this.setLinetypePattern(line_type);
        this.shapes = entitys;
        this._graphics = graphics;
        let length = 0.0;
        for (let i = 0; i < entitys.length; i++) {
            length += entitys[i].getLength();
            this.lengthAt.push(length);
        }
        let line = new segment_1.RLine(new ltpoint_1.LTPoint(0, 0), new ltpoint_1.LTPoint(length, 0));
        this._result_ok = this.exportLine(line, offset);
    }
    exportPainterPaths(ent, angle, cursor) {
        let p = this.getPointAt(cursor.x).point;
        let a = this.getAngleAt(cursor.x);
        this._exporter.exportPainterPaths(ent, a, p);
    }
    exportLineSegment(line, angle) {
        let pp1 = this.getPointAt(line.getStartPoint().x);
        let p1OnShape = pp1.point;
        let i1 = pp1.index;
        let pp2 = this.getPointAt(line.getEndPoint().x);
        let p2OnShape = pp2.point;
        let i2 = pp2.index;
        let a = this.getAngleAt(line.getStartPoint().x);
        if (!p1OnShape.isValid || !p2OnShape.isValid) {
            return null;
        }
        if (line.getLength() < PointTolerance) {
            //绘制点
            super.exportPoint(p1OnShape, angle);
        }
        else {
            this.exportShapesBetween(i1, p1OnShape, i2, p2OnShape, a);
        }
        return null;
    }
    getAngleAt(d) {
        let i = this.getShapeAt(d);
        if (i < 0 || i > this.shapes.length || i > this.lengthAt.length) {
            return 0.0;
        }
        let a = d - (i == 0 ? 0.0 : this.lengthAt[i - 1]);
        return this.shapes[i].getAngleAt(a);
    }
    getShapeAt(d) {
        for (let i = 0; i < this.lengthAt.length; i++) {
            let d1;
            if (i == 0) {
                d1 = 0.0;
            }
            else {
                d1 = this.lengthAt[i - 1];
            }
            let d2 = this.lengthAt[i];
            if (d >= d1 && d <= d2) {
                return i;
            }
        }
        return -1;
    }
    getPointAt(d) {
        let i = this.getShapeAt(d);
        if (i < 0 || i >= this.lengthAt.length || i >= this.shapes.length) {
            return { point: ltpoint_1.LTPoint.nullVector(), index: 0 };
        }
        let a;
        if (i == 0) {
            a = d;
        }
        else {
            a = d - this.lengthAt[i - 1];
        }
        let points = this.shapes[i].getPointsWithDistanceToEnd(a, segment_1.From.FromStart);
        if (points.length <= 0) {
            return { point: ltpoint_1.LTPoint.nullVector(), index: 0 };
        }
        return { point: points[0], index: i };
    }
    exportShapesBetween(i1, p1, i2, p2, angle) {
        for (let i = i1; i <= i2; i++) {
            if (i !== i1 && i !== i2) {
                // whole shape is between points:
                this._exporter.exportShapeSegment(this.shapes[i], angle);
                continue;
            }
            let shape = this.shapes[i].clone();
            if (!shape.isDirected()) {
                continue;
            }
            if (i === i1) {
                //  continue;
                // trim start point:
                shape.trimStartPoint(p1, ltpoint_1.LTPoint.nullVector());
            }
            if (i === i2) {
                // trim end point:
                shape.trimEndPoint(p2);
            }
            this._exporter.exportShapeSegment(shape, angle);
        }
    }
}
exports.RShapesExporter = RShapesExporter;
class RArcExporter extends Exporter {
    exportPainterPaths(ent, angle, cursor) {
        throw new Error("Method not implemented.");
    }
    exportLineSegment(line, angle) {
        throw new Error("Method not implemented.");
    }
    constructor(exporter, entity, line_type) {
        super();
    }
}
exports.RArcExporter = RArcExporter;
