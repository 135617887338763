"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTTextStyle = void 0;
const segment_1 = require("../cad/segment");
const shxparser_1 = require("../cad/shxparser");
//import * as PIXI from 'pixi.js'
class LTTextStyle {
    constructor() {
        this._name = "";
        this._bigFontFileName = "";
        this._fileName = "";
        this._isBackwards = false;
        this._isShapeFile = false;
        this._isUpsideDown = false;
        this._isVertical = false;
        this._obliquingAngle = 0;
        this._priorSize = 0;
        this._textSize = 0;
        this._xScale = 0;
        this._update = false;
        this._shx_style = null;
    }
    saveInfo() {
        let blockinfo = {};
        blockinfo["name"] = this._name;
        blockinfo["bigFontFileName"] = this._name;
        blockinfo["fileName"] = this._fileName;
        blockinfo["isBackwards"] = this._isBackwards;
        blockinfo["isShapeFile"] = this._isShapeFile;
        blockinfo["isUpsideDown"] = this._isUpsideDown;
        blockinfo["isVertical"] = this._isVertical;
        blockinfo["obliquingAngle"] = this._obliquingAngle;
        blockinfo["priorSize"] = this._priorSize;
        blockinfo["textSize"] = this._textSize;
        blockinfo["xScale"] = this._xScale;
        blockinfo["update"] = this._update;
        return blockinfo;
    }
    setInfo(info) {
        this._name = info.name;
        this._bigFontFileName = info.bigFontFileName;
        this._fileName = info.fileName;
        if (this._fileName.length > 0 && this._fileName.lastIndexOf('.') == -1) {
            this._fileName += '.shx';
        }
        this._isBackwards = info.isBackwards;
        this._isShapeFile = info.isShapeFile;
        this._isUpsideDown = info.isUpsideDown;
        this._isVertical = info.isVertical;
        this._obliquingAngle = info.obliquingAngle;
        this._priorSize = info.priorSize;
        this._textSize = info.textSize;
        this._xScale = info.xScale;
        this._update = info.update;
        if (this.hasBigFont) {
            if (!this._fileName.toLowerCase().endsWith(".shx")) {
                this._fileName += '.shx';
            }
            if (!this._bigFontFileName.toLowerCase().endsWith(".shx")) {
                this._bigFontFileName += '.shx';
            }
            this._shx_style = new shxparser_1.ShxRegBigFontFile(this._fileName, this._bigFontFileName);
        }
        else if (this._fileName.endsWith(".shx")) {
            this._shx_style = new shxparser_1.ShxFile(this._fileName);
        }
        if (this._shx_style !== null) {
            this._shx_style.load();
        }
    }
    get valid() {
        if (this._fileName.length == 0 || !this._fileName.endsWith(".shx")) {
            return false;
        }
        if (this._shx_style == null || !this._shx_style.valid()) {
            return false;
        }
        return true;
    }
    get hasBigFont() {
        return this._bigFontFileName !== undefined && this._bigFontFileName.length > 0;
    }
    get hasTextSize() {
        return this._textSize > 0;
    }
    graphicsData(text, font_size, color, vmode, hmode, text_draw_info) {
        if (this._shx_style == null) {
            return null;
        }
        let graphics;
        if (this._shx_style instanceof shxparser_1.ShxFile) {
            graphics = this._shx_style.getGraphicData(text, font_size, color, text_draw_info);
        }
        else {
            graphics = this._shx_style.getGraphicData(text, font_size, color, text_draw_info);
        }
        let bounds = graphics.getBounds();
        let width = 0;
        let height = 0;
        if (hmode == segment_1.TextHorzMode.kTextLeft) {
            width = 0;
        }
        else if (hmode == segment_1.TextHorzMode.kTextCenter) {
            width = bounds.width / 2;
        }
        else if (hmode == segment_1.TextHorzMode.kTextRight) {
            width = bounds.width;
        }
        else if (hmode == segment_1.TextHorzMode.kTextAlign) {
            width = bounds.width;
        }
        if (vmode == segment_1.TextVertMode.kTextBase) {
            height = 0;
        }
        else if (vmode == segment_1.TextVertMode.kTextBottom) {
            height = 0;
        }
        else if (vmode == segment_1.TextVertMode.kTextVertMid) {
            height = bounds.height / 2;
        }
        else if (vmode == segment_1.TextVertMode.kTextTop) {
            height = bounds.height;
        }
        //特殊
        if (hmode == segment_1.TextHorzMode.kTextMid) {
            width = bounds.width / 2;
            height = bounds.height / 2;
        }
        else if (hmode == segment_1.TextHorzMode.kTextFit) {
            // width = bounds.width;
            height = 0;
        }
        let matrix = new PIXI.Matrix();
        matrix.scale(text_draw_info.width_factor, 1);
        matrix.translate(-width * text_draw_info.width_factor, -height);
        matrix.rotate(text_draw_info.rotate);
        matrix.translate(text_draw_info.x, text_draw_info.y);
        // matrix.translate(-width, -height);
        // matrix.rotate(text_draw_info.rotate);
        // matrix.scale(text_draw_info.width_factor,1.0);
        // matrix.translate(text_draw_info.x, text_draw_info.y);
        let datas = graphics.geometry.graphicsData;
        for (let sub_data of datas) {
            sub_data.matrix = matrix;
            sub_data.isExpression = text_draw_info.isexpression;
            for (let sub_hole of sub_data.holes) {
                sub_hole.matrix = matrix;
            }
        }
        return graphics;
    }
    get name() {
        return this._name;
    }
    get bigFontFileName() {
        return this._bigFontFileName;
    }
    get fileName() {
        return this._fileName;
    }
    get isBackwards() {
        return this._isBackwards;
    }
    get isShapeFile() {
        return this._isShapeFile;
    }
    get isUpsideDown() {
        return this._isUpsideDown;
    }
    get isVertical() {
        return this._isVertical;
    }
    get obliquingAngle() {
        return this._obliquingAngle;
    }
    get priorSize() {
        return this._priorSize;
    }
    get textSize() {
        return this._textSize;
    }
    get xScale() {
        return this._xScale;
    }
    set xScale(xscale) {
        this._xScale = xscale;
    }
}
exports.LTTextStyle = LTTextStyle;
