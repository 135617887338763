"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CadSetting = void 0;
//rendermode: true 为纯渲染  false 为可编辑
//import * as PIXI from 'pixi.js'
class CadSetting {
    constructor() {
        this._useOnlyRender = false;
        this._use_standonle_graphics = false;
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    get useOnlyRender() {
        return this._useOnlyRender;
    }
    set useOnlyRender(value) {
        this._useOnlyRender = value;
    }
    get use_standonle_graphics() {
        return this._use_standonle_graphics;
    }
    set use_standonle_graphics(value) {
        this._use_standonle_graphics = value;
    }
    static _newGraphics() {
        // return new window.PIXI.smooth.SmoothGraphics();
        return new PIXI.Graphics();
    }
}
exports.CadSetting = CadSetting;
