"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipeSizeTextDrawer = void 0;
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const defaultTextHeight = 300;
class PipeSizeTextDrawer {
    static getLTText(p1, p2, text, drawscale) {
        let angle = p1.getAngleTo(p2);
        //(90,270]
        if (angle > Math.PI / 2 && angle < Math.PI * 1.5 + 0.1) {
            angle += Math.PI;
        }
        let textPosition = ltpoint_1.LTPoint.getAverage(p1, p2);
        let textHeight = defaultTextHeight * drawscale;
        let textOffset = textHeight / 3 + textHeight / 2;
        //甲方要求的标注文字高度与偏离距离为3:1的关系
        let translation = new ltpoint_1.LTPoint(0, textOffset);
        translation.rotate(angle);
        let ltText = new ltentity_1.LTText(textPosition.plus(translation));
        ltText.subData().fontName = "sprinkler_200";
        //ltText.subData().textVertMode = TextVertMode.kTextBottom;
        //ltText.subData().textHorzMode = TextHorzMode.kTextCenter;
        ltText.setCenter(true);
        ltText.text = text;
        ltText.setRotate(angle);
        ltText.setHeight(textHeight);
        ltText.subData().widthFactor = 0.6;
        return ltText;
    }
}
exports.PipeSizeTextDrawer = PipeSizeTextDrawer;
